@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$primary-color: #194049;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// .slick-slider {
// overflow: auto;
// }

// Mobile screen
@media (min-width: 300px) and (max-width: 849px) {

.text-big {
  margin-left: 0px !important;
}

ol li {
  margin-left: 30px;
}

li ul li {
  margin-left: -40px !important;
}

}

// Ipad Air
@media (min-width: 850px) and (max-width: 890px) {
  .cardozo {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .bodyImage {
    height: 495px !important;
  }
  // .viewImage{
  //   height: 375.83px !important;
  // }
  .podClass {
    gap: 8rem !important;
  }
  .consultantLanding {
    right: -16px !important;
  }
  .landingArtH {
    height: 500px !important;
  }
  .landingPodH {
    height: 470px !important;
  }
  .landingVidH {
    height: 452px !important;
  }
  .cardozoLandingH {
    height: 305px !important;
  }
  .cardozoLandingW {
    width: 305px !important;
  }
  .topair {
    top: 8% !important;
  }
}

// ResArticle Hover Effect
.containered {
  position: relative !important;
  border-radius: 6px !important;
}
.imageArt {
  display: block !important;
}
.containered:hover .imageArt {
  opacity: 0.2 !important;
  background-color: rgb(236, 236, 236) !important;
}
.resContent {
  display: none !important;
}
.containered:hover .resContent {
  display: block !important;
}

.containered:hover .resContent {
  display: block !important;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  // margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  // padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}

.hider {
  ::-webkit-scrollbar {
    display: none;
  }
}

.remove-line {
  text-decoration: none !important;
}

.nohova:hover {
  background-color: transparent !important;
}

// Columbia-Sans (used for headers)
@font-face {
  font-family: 'Columbia-Sans';
  src: url('./static/fonts/columbia-sans/columbiasanswdisplay-medium.woff2')
      format('woff2'),
    url('./static/fonts/columbia-sans/columbiasanswdisplay-medium.eot?#iefix')
      format('embedded-opentype');
}

// Area font family is used for the following:
// a, p, span, ul, li, label, input, textarea, button
@font-face {
  font-family: 'Area-Extended';
  src: url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Black';
  src: url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Bold';
  src: url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Semibold';
  src: url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.otf')
      format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Area-Normal-Semibold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  background-color: #ffffff;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
span,
ul,
li,
label,
input,
textarea,
button {
  font-size: 12px;
  font-family: 'Area-Normal-Semibold', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 12px;
  font-family: 'Columbia-Sans', sans-serif;
}

b{
  color: #194049;
}

.text-huge {
  font-size: 16px;
  font-family: 'Area-Extended', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 102%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #194049;
}
.text-big {
  font-size: 22px;
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
  letter-spacing: 0.02em;
  margin-left: -50px;
  color: #194049;
}
.text-small {
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}
.text-tiny {
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}

.mh-bold {
  font-size: 14px !important;
  font-family: 'Area-Normal-Black', sans-serif;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.04em;
  }

blockquote .text-big {
  font-size: 30px !important;
  font-family: 'Columbia-Sans', sans-serif;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.04em;
}

blockquote .text-small {
  font-size: 30px !important;
  font-family: 'Columbia-Sans', sans-serif;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.04em;
}

  blockquote .text-small {
    font-size: 30px !important;
    font-family: 'Columbia-Sans', sans-serif;
    width: 90%;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.04em;
    }
 

    .text-small strong{
      font-size: 15px !important;
      font-family: 'Area-Normal-Black', sans-serif !important;
      font-style: normal;
      // font-weight: 900;
      line-height: 200%;
      letter-spacing: 0.02em;
      color: #194049;
      }
  .text-small s{
    font-size: 30px !important;
    font-family: 'Columbia-Sans', sans-serif;
    width: 90%;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.04em;
    text-decoration: none !important;
  }


  ol li {
    list-style-type: decimal;
    padding: 10px !important;
    font-size: 16px;
    font-family: 'Area-Extended' !important;
    font-style: normal;
    color:#194049;
    margin-bottom: 5px;
    position: relative;
  }

 

// ol li {
//   list-style-type: decimal;
//   padding: 10px !important;
//   font-size: 16px;
//   font-family: 'Area-Normal', sans-serif;
//   font-style: normal;
//   color: #194049;
//   margin-bottom: 5px;
//   position: relative;
//   font-family: 'Area Extended';
//   font-weight: 900;
// }

  li ul li {
    list-style-type: none;
    padding: 5px !important;
    font-size: 16px;
    font-family: 'Area-Extended' !important;
    font-style: normal;
    color:#194049;
  }

  

  
//  List Style design

// ol li {
//   counter-increment: my-awesome-counter;
//   position: relative;
//   font-family: 'Area-Extended';
//   font-style: normal;
//   font-weight: 900;
//   font-size: 16px;
// }
// ol li::before{
//   content: list-style;
//   color: #194049;
//   position: absolute;
//   --size: 32px;
//   left: calc(-1 * var(--size) - 10px);
//   line-height: var(--size);
//   width: var(--size);
//   height: var(--size);
//   top: -5px;
//   background: #f8f246;
//   border-radius: 50%;
//   text-align: center;
// }
// li ul li::before {
//   list-style-type: none;
//   background-color: transparent;
//   content: none;
// }


#companyContact{
  text-transform: capitalize;
}
#NomineeNames{
  text-transform: capitalize;
}

.ticketName{
  text-decoration: underline !important;
}

::-moz-selection {
  color: $primary-color;
  background: #f4ee46;
}

::selection {
  color: $primary-color;
  background: #f4ee46;
}

.error {
  // -webkit-transition: max-height 1s;
  // -moz-transition: max-height 1s;
  // -ms-transition: max-height 1s;
  // -o-transition: max-height 1s;
  // transition: max-height 1s;
  // // "height: 0" not work with css transitions
  // max-height: 0;
  // overflow: hidden;
  // transition: all 0.2s ease-in;
}

.invalid {
  max-height: 60px;
}

.valid {
  max-height: 0;
}

// Stripe specific styling rules
// .stripe-link-auth::before {
//   color: #666;
//   content: 'Line 1\A Line 2\A Line 3\A';
//   font-size: 12px;
//   display: block;
//   margin-top: 20px;
//   margin-left: 12px;
//   position: absolute;
//   z-index: 1;
// }

a:link,
a:visited,
a:hover,
a:active {
  color: $primary-color;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.required::after {
  content: '*';
  color: rgb(246, 6, 6);
  margin-left: 1px;
  font-size: 0.8rem;
  line-height: 0.7;
}


/* Hopin Container for NavReg Starts */
.hopin-container-nav {
  overflow: hidden;
  position: relative;
  opacity: 0.9;
}

.hopin-container-nav iframe {
  height: 1200px;
  /* left: 200px; */
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  background-color: white;
}

@media (max-width: 1023px) {
  .hopin-container-nav {
    overflow: hidden;
    padding-top: 75.25%;
    position: relative;
    top: 0px;
  }

  .hopin-container-nav iframe {
    border: 0;
    height: 70%;
    /* left: 150px; */
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 780px) {
  .hopin-container-nav {
    overflow: hidden;
    /* padding-top: 75.25%; */
    position: relative;
    height: 700px;
    top: 20px;
  }

  .hopin-container-nav iframe {
    border: 0;
    height: 100%;
    left: 0px;
    position: absolute;
    width: 100%;
    background-color: white;
  }
  .times-nav {
    position: relative;
    top: 90px;
    left: 0px;
    color: blue;
  }
}
/* Hopin Container for NavReg Ends */


.subscribe-widget {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1665998577/Mask_Group_1_q94vna.png');
  height: 240px;
  width: 100%;
  background-color: limegreen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.podcast-component {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1668611192/PodImage_ygt8nk.jpg');
  height: 600px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.podmobile-component {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1668613507/PodImg_hpg5ji.jpg');
  height: 600px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.subscribe-dialog {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1673989119/Group_2576_v2dlpl.png');
  height: 500px;
  width: 100%;
  background-color: #194049;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.subscribe-component {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1665998577/Mask_Group_1_q94vna.png');
  height: 400px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.quotient-component {
  background-image: url('https://mh-resources-production.s3.us-west-1.amazonaws.com/footer-quotient.png');
  height: 400px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.reimbursement-template__background {
  background-image: url('./static/svg/backgrounds/background.svg');
  height: 400px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.summit-stats-component {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1672826632/image_lvc27b.png');
  height: 680px;
  // height: 595px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.summit-stats-component-tabmobile {
  background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1672833648/image_1_w4tfol.png');
  height: 1500px;
  // height: 900px;
  width: 100%;
  background-color: #f8f246;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 15px;
}

// #c8c8c8;
::-webkit-scrollbar-thumb {
  background-color: #f3f4f6;
  width: 8px;
  border-radius: 15px;
}

.horizontal-scroll::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  border-radius: 15px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  height: 4px;
  border-radius: 15px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  opacity: 0.3;
  height: 4px;
  border-radius: 15px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-enter.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
  display: none;
}

.fade-exit {
  opacity: 0;
  z-index: 0;
  display: none;
}

// Loading Icon
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-icon-container {
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loading-icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 5px solid rgba(25, 64, 73, 0.2);
  border-top-color: #f8f246;
  display: inline-block;
  // margin: 0 8px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  position: relative;
}

.overshot {
  -webkit-animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
  animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
}

.downhill {
  -webkit-animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
  animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
}

.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 15s linear infinite;
  padding-left: 5%;
}

.marquee__content-reverse {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: reverse-scroll 15s linear infinite;
  padding-left: 5%;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes reverse-scroll {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

.marquee_vertical {
  --gap: 100rem;
  position: relative;
  display: block;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content_vertical {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-height: 742px;
  animation: slide 15s linear infinite;
  padding-left: 0%;
}

@keyframes slide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(85%);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// .marquee:hover, marquee-continuous:hover {
//   animation-play-state: paused;
// }

// @keyframes animate-marquee {
//   from {
//     transform: translateX(20%);
//   }
//   to {
//     transform: translateX(-100%);
//   }
// }

// react-transition classes below-
// appear?: string | undefined;
// appearActive?: string | undefined;
// appearDone?: string | undefined;
// enter?: string | undefined;
// enterActive?: string | undefined;
// enterDone?: string | undefined;
// exit?: string | undefined;
// exitActive?: string | undefined;
// exitDone?: string | undefined;

// @font-face {
//   font-family: 'Avenir-Book';
//   src: local('Avenir-Book'),
//     url('./static/fonts/avenir/Avenir-Book.otf') format('opentype');
// }
// @font-face {
//   font-family: Gilroy-Regular;
//   src: local('Gilroy-Regular'),
//     url('./static/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
// }
